@import "./vars.less";

// General
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: @font;
}

hr {
  border: 0;
  border-bottom: 1px solid @K200;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  display: inline-block;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: @headingFont;
  font-weight: @fontBold;
  color: @K900;
  margin: 0 0 20px;
  line-height: 1.5;

  &.no-margin {
    margin: 0;
  }
}

h1 {
  font-size: 24px;
}
h2 {
  font-size: 18px;
}
h3 {
  font-size: 16px;
}

p {
  font-family: @font;
  font-size: @font-md;
  line-height: 1.6;
  color: @K900;
  margin: 0 0 20px;
}

.centered {
  @media (min-width: 1023px) {
    margin: 0 auto;
    width: 1300px;
    max-width: 95vw;
  }
  @media (max-width: 1024px) {
    width: 100vw;
    padding: 0 20px;
  }
}

// Alert
.alert-wrap {
  padding: 30px 0;
  background-color: rgba(@R500, 0.1);

  .alert-header,
  .alert-content {
    color: @R500;
  }

  .alert-content {
    margin: 0;
    p,
    a {
      color: @R500;
    }
    a {
      text-decoration: underline;
    }
  }
}

// Header Top
.header-top-wrap {
  background-color: @K100;
}
.header-top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 6px 0;

  font-size: @font-sm;

  &-phone {
    margin-right: 6px;
    width: 16px;
    height: 16px;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  a {
    transition: 0.3s ease all;
    display: flex;
    align-items: center;
    font-weight: @fontRegular;
    color: @K700;

    &:hover {
      opacity: 0.6;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  .nav-icon {
    width: 16px;
    height: 16px;
  }

  .nav-text {
    @media (max-width: 1023px) {
      display: none;
    }
  }

  .nav-icon + .nav-text {
    margin-left: 6px;
  }
}

// Header Bottom
.header-bottom-wrap {
  position: relative;
  z-index: 10;
  background: @white;
  box-shadow: @boxShadow300;

  @media (min-width: 1024px) {
    padding: 25px 0;
  }
  @media (max-width: 1023px) {
    padding: 15px 0;
  }
}

.header-bottom {
  display: flex;
  align-items: center;

  .header-bottom-logo {
    height: 22px;
    width: auto;
  }

  .header-bottom-nav {
    margin-left: auto;

    @media (max-width: 1023px) {
      position: absolute;
      display: block;

      transition: transform 400ms ease-in-out;
      transform: scale(1, 0);
      transform-origin: top;

      width: 100vw;
      left: 0;
      top: 100%;

      background-color: @white;
      box-shadow: @boxShadow300;
    }

    a {
      transition: 0.2s ease all;
      font-family: @headingFont;
      font-size: @font-lg;
      font-weight: @fontBold;
      color: @B800;

      &:hover {
        color: @B500;
      }

      @media (min-width: 1024px) {
        &:not(:last-child) {
          margin-right: 30px;
        }
      }
      @media (max-width: 1023px) {
        width: 100vw;
        padding: 20px;
      }
    }
  }
}

.nav-toggle {
  display: none;

  &:checked ~ .header-bottom-nav {
    transform: scale(1, 1);
  }
}

.hamburger {
  @media (max-width: 1023px) {
    margin-left: auto;
  }
  @media (min-width: 1024px) {
    display: none;
  }

  span {
    position: relative;
  }

  span,
  span::before,
  span::after {
    display: block;
    width: 28px;
    height: 2px;
    border-radius: 2px;
    background: @K900;
  }

  span::before,
  span::after {
    content: "";
    position: absolute;
  }

  span::before {
    bottom: 6px;
  }

  span::after {
    top: 6px;
  }
}

// Main
.main-wrap {
  position: relative;
  z-index: 1;

  @media (max-width: 1023px) {
    .centered {
      padding: 0;
    }
  }
}
.main {
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;

    &-left {
      position: relative;
      width: 40%;
      flex: 1;
      padding: 40px 40px 40px 0;

      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100%;
        min-height: 100vh;
        background: @K100;
      }
    }

    &-right {
      width: 60%;
      padding: 40px 0 40px 40px;
    }
  }

  @media (max-width: 1023px) {
    &-left,
    &-right {
      width: 100%;
      padding: 20px;
    }

    &-left {
      background: @K100;
    }
  }
}

// Boxes

// Box - Product
.box-product {
  padding: 24px;
  background: @white;
  box-shadow: @boxShadow600;
  border-radius: @borderRadius;
  max-height: 600px;
  overflow-y: auto;

  & + .box-product {
    margin-top: 24px;
  }

  &-company {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid @K200;

    h1 {
      margin-bottom: 12px;
    }
  }

  &-img {
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 20px;
  }

  &-buttons {
    display: flex;
  }
}

// Box - Promo (non-active product)
.box-promo {
  padding: 40px 0;

  &-img {
    margin-bottom: 20px;
  }

  & + .box-promo {
    border-top: 1px solid @K200;
  }
}

// Box - Content
.box-content {
  width: 100%;

  & + .box-content {
    margin-top: 40px;
  }
}

.section-heading {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 12px;

  border-bottom: 1px solid @K200;

  font-family: @headingFont;
  font-size: @font-md;
  font-weight: @fontSemiBold;
  color: @K500;
  text-transform: uppercase;
}

.session-id {
  font-family: @headingFont;
  font-size: @font-md;
  text-align: right;
  color: @K600;
  margin-bottom: 0;

  b {
    color: @K700;
  }
}

.marketing-img {
  transition: 0.6s ease opacity;
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 20px;

  &:hover {
    opacity: 0.7;
  }
}

.marketing-video {
  width: 100%;
  height: 327px;
  margin-bottom: 20px;
}
// Buttons
.button {
  transition: 0.2s ease all;
  cursor: pointer;
  display: inline-block;
  padding: 10px 12px;

  border: 1px solid @K200;
  border-radius: @borderRadius;
  background-color: transparent;
  font-size: @font-md;
  color: @B500;
  outline: none;

  font-family: @headingFont;
  font-weight: @fontSemiBold;

  &:hover {
    background: rgba(@B500, 0.1);
    color: @B550;
    border-color: @B550;
  }

  &:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 12px;
  }
}

// Login Button
.login-btn {
  cursor: pointer;
  transition: 0.2s ease all;
  height: 44px;
  padding: 0 16px;

  border-radius: @borderRadius;

  font-family: @headingFont;
  font-size: @font-lg;
  font-weight: @fontSemiBold;
  line-height: 44px;

  &.live {
    background: @B500;
    box-shadow: @boxShadow300;
    color: @white;

    &:hover {
      background: @B550;
    }
  }
  &.play {
    background: transparent;
    border: 1px solid @G500;
    color: @G500;

    &:hover {
      background: rgba(@G500, 0.1);
      border-color: @G550;
      color: @G550;
    }
  }

  & + .login-btn {
    margin-left: 12px;
  }
}

// Latest News
.fw-iframe {
  height: 802px;
}

// Error
.error-container {
  margin: 25vh 25vw;
  font-family: @headingFont;

  &__header {
    font-weight: @fontSemiBold;
    color: @K900;
    font-size: 30px;
    text-align: center;
  }

  p {
    text-align: center;
    font-family: @headingFont;
    font-size: @font-lg;

    a {
      text-decoration: none;
      color: @B500;
      cursor: pointer;
    }
  }
}

// Recent Release
.recent-release {
  transition: 0.3s ease all;
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  padding: 8px 12px;

  border-radius: @borderRadius;
  background: rgba(@B500, 0.05);
  border: 1px solid rgba(@B500, 0.5);

  &-text {
    flex: 1;
    margin: 0 10px;

    p {
      margin: 0;
      font-family: @headingFont;
      font-size: @font-md;
      color: @K900;
    }

    &-date {
      font-weight: @fontMedium;
    }

    &-heading {
      font-weight: @fontSemiBold;
    }
  }

  &-more {
    transition: 0.3s ease all;
    font-size: @font-md;
    font-family: @headingFont;
    font-weight: @fontBold;

    color: @B500;
  }

  &:hover {
    background: rgba(@B500, 0.1);
    border: 1px solid rgba(@B600, 0.9);

    .recent-release-more {
      color: @B600;
    }
  }
}

//brower update alert
.buorg {
  animation: unset !important;
  font-family: @font !important;
  border-bottom: none !important;
  box-shadow: none !important;

  .buorg-pad {
    text-align: left;
    padding: 0px;

    .buorg-icon {
      display: none;
    }

    .alert-wrap {
      background-color: rgba(@Y500, 0.1);

      .alert-header {
        color: @O500;
      }

      .alert-content {
        color: @K900;

        a {
          text-decoration: none;
          color: @B500;
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
  }
}
